import React, { useEffect, useState } from "react";
import useTitle from "../hooks/useTitle";
import { useForm } from "react-hook-form";
import { sendComplaintMail } from "../webservices/getway";
import { toast } from "react-toastify";

const ComplaintPage = () => {
    const [loading, setLoading] = useState(false)
    const title = useTitle()

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const sendComplaintEmail = async (data) => {
        setLoading(true);

        let res = await sendComplaintMail(data)

        if (res && res.status) {
            toast.success(res.message)
            setLoading(false);
            return;
        }

        console.log(res);

        setLoading(false)
        reset()
        return toast.error(res && res.message)
    };

    useEffect(() => {
        title("Complaint")
    }, [])
    return (
        <>
            <section
                className="container-fluid py-5 wow fadeInUp"
                data-wow-delay="0.1s"
            >
                <div className="tab-content mt-5">
                    <div
                        id="FormforIndividual"
                        className="container tab-pane animated slideInLeft bg-hero rounded-3 shadow p-3 active"
                    >
                        <h4>Complaint Form</h4>
                        <br />
                        <h5 className="ms-4 mb-4">Welcome to Muhuratinvesting Advisor Helpline ! Consumer Grievances and Redressal System
                            For service grievances one can reach us by registering their concern on below form:</h5>
                        <form onSubmit={handleSubmit(sendComplaintEmail)}>
                            <div className="row">
                                <div className="form-group col-xl-4 col-lg-4 col-md-6 ">
                                    {/* <label for="text">Full Name form:</label> */}
                                    <div className="input-container">
                                        <input
                                            className="form-control border-0"
                                            name="fullname"
                                            id="fullname"
                                            placeholder="Full Name"
                                            type="text"
                                            {...register('fullname', { required: "full name is required" })}
                                        />
                                        {errors.fullname && <p className="text-danger">fullname is required.</p>}
                                    </div>
                                </div>
                                <div className="form-group col-xl-4 col-lg-4 col-md-6">
                                    {/* <label for="text">Father/Spouse Name :</label> */}
                                    <div className="input-container">
                                        {/* <i class="fas fa-user-friends icon" aria-hidden="true"></i> */}
                                        <input
                                            className="form-control border-0"
                                            id="mobile"
                                            name="mobile"
                                            placeholder="Mobile Number"
                                            type="number"
                                            {...register('number', { required: "number is required" })}
                                        />
                                        {errors.number && <p className="text-danger">Mob number is required.</p>}
                                    </div>
                                </div>

                                <div className="form-group col-xl-4 col-lg-4 col-md-6 ">
                                    {/* <label for="text">Nationality :</label> */}
                                    <div className="input-container">
                                        {/* <i class="fas fa-id-card-alt icon" aria-hidden="true"></i> */}
                                        <input
                                            className="form-control border-0"
                                            name="email"
                                            id="email"
                                            placeholder="Email "
                                            type="email"
                                            {...register('email', { required: "email is required" })}
                                        />
                                        {errors.email && <p className="text-danger">email is required.</p>}
                                    </div>
                                </div>
                                <div className="form-group col-12 my-3">
                                    {/* <label for="text">Specify the proof of Identity submitted :</label> */}
                                    <textarea
                                        className="form-control border-0"
                                        rows={3}
                                        name="message"
                                        id="message"
                                        placeholder="Write Your Complaint Here..."
                                        style={{ background: "#fff", color: "#000" }}
                                        {...register('complaint', { required: "complaint is required" })}
                                    />
                                </div>
                                <div className="form-group col-xl-6 col-lg-6 col-md-6 mt-3 d-flex justify-content-end">
                                    <button
                                        type="submit"
                                        className="btn submit buttons career_btn"
                                    >
                                        {
                                            loading ? <div class="spinner-border text-primary" role="status" >
                                                <span class="visually-hidden">Loading...</span>
                                            </div> : "Submit"}

                                    </button>
                                </div>
                            </div>
                        </form>
                    </div >
                </div >
                {/* Tab panes end */}
            </section >
            <div className="container-fluid py-5">
                <div className="container">
                    <div className="row justify-content-center g-5">
                        <div
                            className="col-xl-12 col-lg-12 col-md-12 col-sm-12 wow slideInUp"
                            data-wow-delay="0.1s"
                        >
                            <div className="bg-hero rounded-3 shadow h-100 p-5">
                                <div className="section-title">
                                    <h5 className="position-relative d-inline-block text-primary text-uppercase">
                                        Contact Us
                                    </h5>
                                    <h1 className="display-6 mb-4">Feel Free To Contact Us</h1>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    <i className="bi bi-person-fill fs-1 text-primary me-2" />
                                    <div className="text-start">
                                        <h5 className="mb-0">Name </h5>
                                        <span>Vishal Borasi Proprietor Muhurat Investing - Investment Advisor</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    <i className="bi bi-geo-alt fs-1 text-primary me-3" />
                                    <div className="text-start">
                                        <h5 className="mb-0">Our Office</h5>
                                        <span>13,14- Mukharji Nagar Indore 452015 M.P.</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-2">
                                    <i className="bi bi-envelope-open fs-1 text-primary me-3" />
                                    <div className="text-start">
                                        <h5 className="mb-0">Email Us</h5>
                                        <a href="mailto:info@muhuratinvesting.com">
                                            <span>info@muhuratinvesting.com</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <i className="bi bi-phone-vibrate fs-1 text-primary me-3" />
                                    <div className="text-start">
                                        <h5 className="mb-0">Call Us</h5>
                                        <a href="tel:9131628434">
                                            <span>+919584096836</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*----------- Forms end -----------*/}
        </>
    );
};

export default ComplaintPage;
