export const RoutePath = {
    HOME: '/',
    ABOUT: '/about',
    SERVICES: '/services',
    CONTACT: '/contact',
    COMPLAINTS: '/complaints',
    COMPLAINTS_BOARD: '/complaint-Board',
    EKYC: '/ekyc',
    RPM: '/rpm',
    BANK_DETAILS:"/payment-page",
    EQUITY_CASH:"equity-cash",
    EQUITY_FUTURE:"equity-future",
    INDEX_OPTION:"index-options",
    SHORT_TERM:"short-term-investment",
    LONG_TERM:"long-term-investment",
    DAILY_RECOMMENDATION:"daily-recommendation",
    BTST:"btst",
    GOAL_SETTING:"goal-setting",
    CASH:"/cash",
    STOCK_CASH:"stock-cash",
    HNI_CASH:"hni-cash",
    INDEX:"/index",
    INDEX_HNI_OPTION:"hni-index-option",
    INDEX_OPTION1:"index-option",
    FUTURE:"/future",
    STOCK_FUTURE:"stock-future",
    HNI_FUTURE:"hni-future",
    OPTION:"/option",
    HNI_OPTION:"hni-option",
    STOCK_OPTION:"stock-option",
    COMPLIANCE:"/compliance",
    DISCLAIMER:"/disclaimer",
    DISCLOSURE:"/disclosure",
    PRIVACY:"/privacy-policy",
    INVESTOR_CHARTER:"/investor-charter",
    CANCELLATION:"/cancellation",
}