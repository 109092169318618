import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { RoutePath } from "./RoutePath";
import * as PAGE from "./pages/PagePath";
import PublicRoute from "./routes/publicRoute";
import * as COMPONENT from "./components/ComponentRoute"
import ScrollTop from "./components/scollTop/scrollTop";
import { StockData } from "./pages/stockOptionPage/pageData";
import BackToTopButton from "./components/BackToTopButton";
import { ToastContainer } from "react-toastify";

function App() {

  return (
    <>
      <ScrollTop />
      <BackToTopButton />
      <Routes>
        <Route path={RoutePath.HOME} element={<PublicRoute />}>
          <Route index element={<PAGE.HOME_PAGE />} />

          <Route path={RoutePath.ABOUT} element={<PAGE.ABOUT_PAGE />} />

          <Route path={RoutePath.SERVICES} element={< COMPONENT.LAYOUT />} >
            <Route path={RoutePath.EQUITY_CASH} element={<PAGE.EQUITY_CASH_PAGE />} />
            <Route path={RoutePath.EQUITY_FUTURE} element={<PAGE.EQUITY_FUTURE_PAGE />} />
            <Route path={RoutePath.INDEX_OPTION} element={<PAGE.INDEX_OPTION_PAGE />} />
            {/* <Route path={RoutePath.DAILY_RECOMMENDATION} element={<PAGE.DAILY_RECOMMENDATION_PAGE />} /> */}
            <Route path={RoutePath.GOAL_SETTING} element={<PAGE.GOAL_SETTING_PAGE />} />
            {/* <Route path={RoutePath.BTST} element={<PAGE.BTST_PAGE />} /> */}
            <Route path={RoutePath.SHORT_TERM} element={<PAGE.SHORT_TERM_PAGE />} />
            <Route path={RoutePath.LONG_TERM} element={<PAGE.LONG_TERM_PAGE />} />
          </Route>


          {/* <Route path={RoutePath.CASH} element={< COMPONENT.LAYOUT />} >
            <Route index element={<Navigate to={`${RoutePath.CASH}/${RoutePath.STOCK_CASH}`} />} />
            <Route path={RoutePath.STOCK_CASH} element={<PAGE.STOCK_PLAN_PAGE data={StockData?.cash?.stock_cash} />} />
            <Route path={RoutePath.HNI_CASH} element={<PAGE.STOCK_PLAN_PAGE data={StockData?.cash?.hni_cash} />} />
          </Route> */}

          {/* <Route path={RoutePath.INDEX} element={< COMPONENT.LAYOUT />} >
            <Route index element={<Navigate to={`${RoutePath.CASH}/${RoutePath.INDEX_FUTURE}`} />} />
            <Route path={RoutePath.INDEX_HNI_OPTION} element={<PAGE.STOCK_PLAN_PAGE data={StockData?.index?.index_future} />} />
            <Route path={RoutePath.INDEX_OPTION1} element={<PAGE.STOCK_PLAN_PAGE data={StockData?.index?.index_option} />} />
          </Route> */}

          {/* <Route path={RoutePath.FUTURE} element={< COMPONENT.LAYOUT />} >
            <Route index element={<Navigate to={`${RoutePath.CASH}/${RoutePath.STOCK_FUTURE}`} />} />
            <Route path={RoutePath.STOCK_FUTURE} element={<PAGE.STOCK_PLAN_PAGE data={StockData?.future?.stock_future} />} />
            <Route path={RoutePath.HNI_FUTURE} element={<PAGE.STOCK_PLAN_PAGE data={StockData?.future?.hni_future} />} />
          </Route> */}

          {/* <Route path={RoutePath.OPTION} element={< COMPONENT.LAYOUT />} >
            <Route index element={<Navigate to={`${RoutePath.CASH}/${RoutePath.STOCK_OPTION}`} />} />
            <Route path={RoutePath.STOCK_OPTION} element={<PAGE.STOCK_PLAN_PAGE data={StockData?.Option?.stock_option} />} />
            <Route path={RoutePath.HNI_OPTION} element={<PAGE.STOCK_PLAN_PAGE data={StockData?.Option?.hni_option} />} />
          </Route> */}

          <Route path={RoutePath.EKYC} element={<PAGE.EKYC_PAGE />} />
          <Route path={RoutePath.CONTACT} element={<PAGE.CONTACT_PAGE />} />
          <Route path={RoutePath.COMPLAINTS} element={<PAGE.COMPLAINTS_PAGE1 />} />
          <Route path={RoutePath.COMPLAINTS_BOARD} element={<PAGE.COMPLAINTS_PAGE />} />
          <Route path={RoutePath.BANK_DETAILS} element={<PAGE.BANK_DETAILS_PAGE />} />
          <Route path={RoutePath.RPM} element={<PAGE.RPM_PAGE />} />
          <Route path={RoutePath.DISCLAIMER} element={<PAGE.DISCLAIMER_PAGE />} />
          <Route path={RoutePath.CANCELLATION} element={<PAGE.CANCELLATION_PAGE />} />
          <Route path={RoutePath.DISCLOSURE} element={<PAGE.DISCLOSURE_PAGE />} />
          <Route path={RoutePath.PRIVACY} element={<PAGE.PRIVACY_PAGE />} />
          <Route path={RoutePath.COMPLIANCE} element={<PAGE.COMPLIANCE_PAGE />} />
          <Route path={RoutePath.INVESTOR_CHARTER} element={<PAGE.INVESTOR_CHARTER />} />
          <Route path="*" element={<PAGE.NOT_FOUND_PAGE />} />
        </Route>

        {/* not found page */}
        <Route path="/investment-advisor" element={<PAGE.LANDING_PAGE />} />
        <Route path="/Intraday-option-call" element={<PAGE.INTRADAY_OPTION_CALL />} />
        <Route path="/get-5-trading-stock" element={<PAGE.GET_5_TRANDING />} />
        <Route path="*" element={<PAGE.NOT_FOUND_PAGE />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
